.document-page {
  padding: 20px;

  // Card Container for the First 6 Cards
  .card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
    position: relative;

    // Rectangle line background between two rows of cards
    .background-rectangle {
      position: absolute;
      top: 160px; // Adjusted to place the rectangle between two rows
      left: 0;
      right: 0;
      height: 100px;
      background-color: rgba(0, 200, 0, 0.2); // Light green background color
      z-index: 1;
    }

    // Styling for individual cards
    .card {
      width: 30%; // Slightly bigger cards
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      position: relative;
      z-index: 2;

      .icon-circle {
        background-color: #32cd32;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin: 0 auto 10px;
      }

      h3 {
        font-size: 24px;
        margin-top: 10px;
        font-weight: bold;
      }

      p {
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }

  // Styling for the Required Documents Section
  .required-documents {
    text-align: center;
    margin-top: 40px;

    h2 {
      font-size: 50px;
      color: #32cd32;
      margin-bottom: 20px;
      font-weight: bold;
    }

    p {
      margin-bottom: 30px;
      font-size: 20px;
      color: #0057ff;
    }

    // Container for the Document Cards
    .document-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      // Individual Document Cards
      .document-card {
        width: 18%; // Smaller width for the document cards
        margin-bottom: 20px;
        border-radius: 8px;
        padding: 15px;
        text-align: center;
        border: 2px solid #32cd32;
        position: relative;

        // Number inside the circle
        .document-number {
          background-color: #32cd32;
          color: white;
          font-size: 24px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          margin: 0 auto 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          font-size: 16px;
          color: black;
          margin: 0;
          font-weight: bold;
        }
      }
    }
  }
}

// Media queries for responsive design
@media only screen and (max-width: 768px) {
  .document-page {
    padding: 10px;

    .card-container,
    .required-documents .document-container {
      flex-direction: column;
      align-items: center;

      .card,
      .document-card {
        width: 100%; // Full-width for mobile view
        margin-bottom: 20px;
      }

      .document-card:nth-child(4),
      .document-card:nth-child(5) {
        margin-left: 0; // Reset margin for mobile view
      }
    }

    .background-rectangle {
      top: 220px; // Adjust rectangle position for mobile
    }
  }
}
