// Import a modern font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to right, #00c6ff, #00ca00);
  background-size: cover;
  background-position: center;
}

.auth-box {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.auth-title {
  font-size: 32px;
  margin-bottom: 32px;
  font-weight: 700;
  color: #2c3e50;
}

.auth-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.auth-input {
  width: 100%;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.auth-input:focus {
  border-color: #00ca00;
}

.auth-button {
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(to right, #00c6ff, #00ca00);
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.auth-button:hover {
  transform: translateY(-2px);
}

.auth-footer {
  margin-top: 30px;
}

.auth-footer p {
  font-size: 16px;
  color: #666;
}

.auth-google-button {
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  border: none;
  background: #db4437;
  background-color: #4285F4; 
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 16px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #357ae8;
  }

  svg {
    font-size: 20px; 
    margin: -4px;

  }
}

.auth-google-button:hover {
  background-color: #c1351d;
}

.auth-link-button {
  background: none;
  border: none;
  color: #00ca00;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.auth-link-button:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .auth-box {
    padding: 25px;
  }

  .auth-title {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .auth-box {
    padding: 20px;
  }

  .auth-title {
    font-size: 24px;
  }

  .auth-button, .auth-google-button {
    font-size: 16px;
  }
}
.input-error {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

