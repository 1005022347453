// .invest-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center; /* Center images horizontally */
//     padding: 20px; /* Add some padding around the container */
//   }
  
//   .invest-image {
//     max-width: 100%; /* Ensure images are responsive */
//     margin-bottom: 20px; /* Space between images */
//     border-radius: 8px; /* Optional: Add rounded corners to the images */
//   }
  

/* Ensure the container takes up the entire page and centers the image */
.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Full viewport height */
  background-color: #f0f0f0;  /* Light gray background */
  margin: 0;
  padding: 0;
}

.coming-soon-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;  /* Ensures the image scales properly */
}
