.input-container {
  position: relative;
  background-color: #f3f4f6;
  padding: 0.75rem;
  border-radius: 9999px;
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.destination-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 768px) {
  .destination-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.location-button {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00ca00;
  padding: 15px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.location-button:hover {
  background-color: #009a00;
  transform: translateY(-50%) scale(1.1);
}

.location-button:active {
  transform: translateY(-50%) scale(0.95);
}

.location-icon {
  width: 20px;
  height: 20px;
  color: white;
  transition: color 0.3s ease;
}

.location-button:hover .location-icon {
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .location-button {
    right: 0rem;
    padding: 7px;
  }
  .css-1q9b57r-control {
    width: 125% !important;
    margin-left: -10px;
  }

  .css-1n7v3ny-menu {
    min-width: calc(100% + 10px);
    width: calc(100% + 10px);
  }

  .css-1n7v3ny-placeholder {
    font-size: 10px;
  }

  .truncated-address {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
  }
}
