.footer {
  background-color: #f9f9f9;
  padding: 40px 20px;
  font-family: 'DM Sans', sans-serif;
  color: #333;
  border-top: 2px solid #e0e0e0;

  .footer-content {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-logo-section {
    flex: 1 1 200px;
    max-width: 250px;
    text-align: center; // Center align for mobile view

    .footer-logo {
      width: 180px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        width: 150px;
      }
    }

    .footer-description {
      font-size: 16px;
      color: #666;
      margin-bottom: 20px;
      padding: 0 20px; // Adding padding to reduce text width on mobile
    }

    .footer-socials {
      display: flex;
      gap: 10px;
      justify-content: center; // Center icons for mobile view

      .footer-social-icon {
        font-size: 20px;
        color: #1c1c1e;
        cursor: pointer;

        &:hover {
          color: #017cfd;
        }
      }
    }
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    flex: 2 1 600px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .footer-column {
      flex: 1;
      min-width: 150px;
      margin: 10px 0; // Add margin between columns on mobile

      @media (max-width: 768px) {
        text-align: center;
        margin: 10px 0;
      }

      .footer-title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        color: #1c1c1e;

        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          font-size: 16px; // Slightly reduce font size for mobile
          color: #666;
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: 40px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
    font-size: 14px;
    color: #666;

    a {
      color: #666;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
