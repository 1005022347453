.drive-with-us-container {
  text-align: center;
  padding: 40px 5%; // Reduced padding and used percentage for better responsiveness

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    gap: 20px; // Add gap between text content and image to reduce blank space

    .text-content {
      flex: 1;
      text-align: left;
      margin-left: 5%; // Add margin to the left for more balanced spacing
      max-width: 600px; // Constrain width for better text alignment and readability

      h1 {
        font-size: 2rem;
        color: #32CD32;
        margin-bottom: 10px; // Reduce gap between text
      }

      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        color: #32CD32;
        margin-bottom: 20px; // Reduce the space between header and button
      }

      .apply-button {
        margin-top: 20px;
        padding: 15px 40px;
        background-color: #32CD32;
        border: none;
        color: white;
        font-size: 1.2rem;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #008c39;
        }
      }
    }

    .image-placeholder {
      width: 40%; // Reduced width for better responsiveness
      max-width: 550px; // Set max width for large screens
      height: auto;
      border-radius: 8px;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; // Ensure the image fills the placeholder without distortion
      }
    }
  }

  .why-us-section {
    margin-top: 60px;
    position: relative;

    h2, h3 {
      font-size: 2.2rem; // Adjusted size for better balance
      color: #32CD32;
      margin-bottom: 10px;
      font-weight: bold;
    }

    p {
      font-size: 1.2rem; // Reduced font size for better readability
      color: #0057FF;
      margin: 10px 0;
    }

    .ellipse-bg {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 400px;
      height: 200px;
      z-index: -1;
    }
  }

  // Responsive adjustments
  @media (max-width: 1024px) {
    .top-section {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .text-content {
        text-align: center;
        margin-left: 0; // Center text on smaller screens
        margin-bottom: 20px; // Add spacing below text content
      }

      .image-placeholder {
        width: 80%;
        max-width: 300px;
      }
    }

    .why-us-section {
      h2, h3 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;

    .top-section {
      .text-content {
        h1 {
          font-size: 1.5rem;
        }

        h2 {
          font-size: 2rem;
        }

        .apply-button {
          font-size: 1rem;
          padding: 10px 30px;
        }
      }

      .image-placeholder {
        width: 100%;
        max-width: 250px;
      }
    }

    .why-us-section {
      h2, h3 {
        font-size: 1.6rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  @media (max-width: 480px) {
    .top-section {
      .text-content {
        h1 {
          font-size: 1.3rem;
        }

        h2 {
          font-size: 1.6rem;
        }

        .apply-button {
          font-size: 0.9rem;
          padding: 10px 20px;
        }
      }

      .image-placeholder {
        max-width: 200px;
      }
    }

    .why-us-section {
      h2, h3 {
        font-size: 1.4rem;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }
}
