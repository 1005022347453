.apply-to-drive {
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      opacity: 0;
      animation: fadeIn 0.3s forwards;
    }
  
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  
    .modal-content {
      background-color: white;
      padding: 40px;
      border-radius: 12px;
      width: 500px;
      max-width: 90%;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      transition: transform 0.3s ease;
  
      .modal-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 25px;
  
        h2 {
          font-size: 1.9rem;
          color: #32CD32;
          font-weight: bold;
          
        }
  
        .close-button {
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: #000;
          transition: color 0.3s;
  
          &:hover {
            color: #ff0000;
          }
        }
      }
  
      .modal-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .modal-form {
          display: flex;
          flex-direction: column;
          width: 100%;
  
          .modal-input {
            width: 100%;
            padding: 12px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 6px;
            font-size: 1.1rem;
            transition: border-color 0.3s, box-shadow 0.3s;
  
            &:focus {
              border-color: #32CD32;
              outline: none;
              box-shadow: 0 0 5px rgba(50, 205, 50, 0.5);
            }
          }
  
          .submit-button {
            padding: 12px 0;
            background-color: #32CD32;
            color: white;
            border: none;
            border-radius: 6px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.2s;
            margin-top: 12px;
  
            &:hover {
              background-color: #008c39;
              transform: scale(1.03);
            }
  
            &:disabled {
              background-color: #a9a9a9;
              cursor: not-allowed;
              opacity: 0.6;
            }
          }
        }
  
        .thank-you-message {
          font-size: 1.2rem;
          color: #32CD32;
          text-align: center;
          margin-top: 20px;
        }
      }
  
      .modal-footer {
        margin-top: 25px;
        width: 100%;
        display: flex;
        justify-content: center;
  
        .footer-button {
          padding: 10px 25px;
          background-color: #f0f0f0;
          
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: darken(#f44336, 10%);
            transform: scale(1.05); // Slight scale effect on hover
          }
        }
      }
    }
  
    // Responsive adjustments
    @media (max-width: 768px) {
      .modal-content {
        width: 100%;
        padding: 25px;
  
        .modal-header h2 {
          font-size: 1.6rem;
        }
  
        .modal-body {
          .thank-you-message {
            font-size: 1rem;
          }
  
          .modal-form {
            .submit-button {
              font-size: 1rem;
            }
          }
        }
      }
    }
  
    @media (max-width: 480px) {
      .modal-content {
        padding: 18px;
  
        .modal-header h2 {
          font-size: 1.4rem;
        }
  
        .modal-body {
          .modal-form {
            .submit-button {
              padding: 10px 0;
              font-size: 0.9rem;
            }
  
            .modal-input {
              font-size: 0.9rem;
              padding: 10px;
            }
          }
  
          .thank-you-message {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  