// CarListOptions.scss
  // Variables
  $primary-color: #00ff00; // Replace with the green shade from your design
  $border-color: #a4d4ff; // Light blue border color for ride cards
  $background-color: #f5f9ff; // Light grayish-blue background color for ride cards
  $text-color: #333; // Darker text color
  $font-poppins-bold: "Poppins";
.car-list-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-height: 80vh; // Adjust height as needed
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); // More pronounced shadow

  border-radius: 6px;
  margin-top: 20px;

  .car-list-content {
    padding: 20px;
    height: calc(100% - 60px); // Adjust based on button height
    overflow-y: auto;

    .car-list-heading {
      font-size: 22px;
      font-weight: bold;
    }

    .car-list-scrollable {
      max-height: 100%;
      overflow-y: auto;

      // Scrollbar styles
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #00ca00;
        border-radius: 4px;
      }
    }
  }

  .car-list-buttons {
    position: absolute;
    bottom: 0;
    left: 0; // Align buttons to the left
    width: 100%; // Full width of container
    padding: 10px;
    background-color: white; // Ensure the background is visible
    box-sizing: border-box; // Include padding in width
    display: flex;
    gap: 26px;
    justify-content: flex-start; // Align buttons to the left

    .book-ride-btn {
      display: flex;
      align-items: center;
      background-color: black;
      border: none;
      border-radius: 8px;
      padding: 12px 20px;
      color: white;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      transition: background-color 0.3s ease, transform 0.3s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .icon {
        margin-right: 10px; // Adjust spacing as needed
        font-size: 20px; // Adjust size as needed
      }

      &:hover {
        background-color: darken(black, 10%);
        transform: scale(1.05);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
      }
    }

    .make-payment-btn {
      border: none;
      border-radius: 8px;
      padding: 12px 20px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
    }

    .book-ride-btn {
      background-color: black;
    }
  }
  .custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    outline: none;
    z-index: 1000; /* Make sure this is high enough */
  }

  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999; /* Lower than the modal itself */
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
  }
  .modal-content {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    padding: 0; // Remove padding from the container
    position: relative;
    width: 675px;
    transition: transform 0.3s ease;
  
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #1e1e1e;
      padding: 20px 24px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      box-shadow: inset 0 -1px 4px rgba(0, 0, 0, 0.1);
      color: #f5f5f5;
      font-family: "Poppins", sans-serif;
  
      .modal-title {
        font-size: 1.75rem;
        font-weight: 600;
        color: #32CD32; // Add a pop of color to the title
      }
  
      .modal-close {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        color: #ffcc00;
        transition: color 0.2s ease, transform 0.2s ease; // Added transform transition
      
        transform: rotate(10deg); // Slightly tilt to the right
      
        &:hover {
          color: #fff;
          transform: rotate(15deg); // Slightly increase tilt on hover
        }
      
        &:active {
          transform: rotate(10deg) scale(0.95); // Keep tilt and add scale effect on click
        }
      
        &:focus {
          outline: none;
        }
      }
      
    }
  
    .modal-body {
      padding: 24px; // Increase padding for better content spacing
      font-family: "Poppins", sans-serif;
      color: #333;
    }
  
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      padding: 20px 24px;
      background: #f5f5f5;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
  
      .modal-close-btn {
        background: linear-gradient(135deg, #00ca00 0%, #84fb84 100%);
        color: #fff;
        border: none;
        border-radius: 8px;
        padding: 10px 24px;
        cursor: pointer;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: bold;
        transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  
        &:hover {
          background: linear-gradient(135deg, #00ca00 0%, #84fb84 100%);

          transform: scale(1.05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
  
        &:focus {
          outline: none;
          box-shadow: 0 0 0 4px rgba(57, 221, 42, 0.4);
        }
      }
    }
  }
  
  
  .ride-cards {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  
    .ride-card {
      background-color: #f5f9ff;
      border: 2px solid #a4d4ff;
      border-radius: 15px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 290px;
      cursor: pointer; // Add cursor pointer for hover effect
  
      transition: transform 0.2s ease, box-shadow 0.2s ease; // Added transition for smooth hover effect
  
      &:hover {
        transform: translateY(-5px); // Slight lift effect on hover
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); // Enhanced shadow on hover
      }
  
      &__text {
        font-family: $font-poppins-bold;
        font-size: 30px;
        margin-bottom: 10px;
        color: $text-color;
      }
  
      &__image {
        margin-top: -45%;
        img {
          width: 100%;
          height: auto;
          mix-blend-mode: multiply;
        }
      }
    }
  }
  
}
