// Variables
$primary-color: #00ff00; // Green shade
$border-color: #a4d4ff; // Light blue border color for ride cards
$background-color: #f5f9ff; // Light grayish-blue background color for ride cards
$text-color: #333; // Darker text color
$font-poppins-bold: "Poppins";

// Main container
.ride-with-us {
  padding: 90px 80px;

  // Riding Experience Section
  .riding-experience {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    .content {
      flex: 1;
      max-width: 40%;

      h1 {
        font-family: "Poppins", sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        color: #00ca00;
        margin: 0;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-weight: 500;
        margin: 20px 0;
        color: #00ca00;
      }

      .ride-options {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ride-card {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 16px;
          border: 2px solid #00ca00;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          max-width: 450px;

          span {
            font-size: 18px;
            font-weight: 600;
            color: #00ca00;
          }

          .arrow {
            font-size: 20px;
            font-weight: bold;
            color: #00ca00;
          }

          &:hover {
            background-color: #00ca00;

            span,
            .arrow {
              color: #fff;
            }
          }
        }
      }
    }

    .image-container {
      flex: 1;
      max-width: 70%;
      height: 100%;
      border-radius: 8px;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }

  // Oval Cards Section
  .oval-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 40px 0;

    .oval-card {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $primary-color;
      border-radius: 50px;
      padding: 20px 30px;
      font-family: $font-poppins-bold;
      font-size: 22px;
      color: $primary-color;
      box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
      text-align: center;

      span {
        z-index: 1;
      }

      .border-icon {
        position: absolute;
        top: -20px;
        left: 5%;
        transform: translateX(-50%);
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }

  // Map Container
  .map-container {
    margin-top: 40px;
    padding: 20px;
    border: 2px solid #00ca00;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    iframe,
    .map {
      width: 100%;
      height: 400px;
      border: 0;
      border-radius: 8px;
    }

    // Media query for mobile devices
    @media (max-width: 768px) {
      padding: 10px;
      margin-top: 20px;
      width: 360px;
      margin-left: -83px;

      iframe,
      .map {
        height: 350px;
        min-height: 300px;
        width: 100%;
      }
    }

    @media (max-width: 480px) {
      padding: 8px;
      margin-left: -73px;
      iframe,
      .map {
        height: 320px;
        min-height: 280px;
      }
    }
  }

  // Responsive Design
  @media (max-width: 768px) {
    .riding-experience {
      flex-direction: column;
      align-items: flex-start;

      .content,
      
      .image-container {
        max-width: 100%;
        img {
          width: 150%;
          margin-left: -45px;
          display: none;
        }
      }

      .ride-options .ride-card {
        font-size: 16px;
        width: 150%;
        margin-left: -45px;

        span {
          font-size: 16px;
        }

        .arrow {
          font-size: 18px;
        }
      }
    }

    .oval-cards {
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .oval-card {
        width: 150%;
        padding: 9px;

        .border-icon {
          top: -12px;
          left: 3%;
          height: 35px;
        }
        span {
          font-size: 20px;
        }
      }
    }
  }
}
