.home {
  .promo-section {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    font-family: Poppins;
    color: #fff;

    .promo-banner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 125px 20px 304px 100px;
      border-radius:10px;
      overflow: hidden; // Ensure the video does not overflow
      box-shadow: 0 10px 7px rgba(0, 87, 255, 0.25);
      .promo-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; // Ensure the video covers the entire banner
        z-index: 1;
        background: #000; // Fallback background color
        box-shadow: 0 4px 4px rgba(0, 87, 255, 0.25);

      }


      .promo-background {
        width: 100%;
        height: 600px;
        position: relative;
        display: none;
        background: inherit;
        border-radius: inherit;
      }

      .promo-title {
        margin: 0;
        font-size: 40px;
        font-weight: 700;
        position: relative;
        z-index: 2;

        p {
          margin: 0;
        }
      }

      .promo-subtitle {
        margin: 0;
        font-size: 30px;
        font-weight: 500;
        position: relative;
        z-index: 2;
      }
    }

    .promo-content {
      display: flex;
      margin-top: -389px;
      padding: 0 20px;
      justify-content: end;

      .promo-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 350px;
        padding: 15px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 4px 4px rgba(0, 87, 255, 0.25);
        z-index: 3;

        .card-shadow {
          width: 100%;
          height: 473px;
          display: none;
          box-shadow: inherit;
          border-radius: inherit;
          background-color: #fff;
        }

        .card-image {
          flex: 1;
          border-radius: 20px;
          overflow: hidden;
          object-fit: cover;
          z-index: 3;
        }

        .get-started-button {
          margin-top: 20px;
          width: 321px;
          height: 69px;
          background-color: #00ca00;
          border-radius: 10px;
          cursor: pointer;
          border: none;
          padding: 20px;
          text-align: center;
          z-index: 3;

          &:hover {
            background-color: #1ae31a;
          }

          .button-background {
            display: none;
          }

          .button-text {
            font-size: 24px;
            font-weight: 600;
            color: #fff;
          }
        }
      }
    }
  }

  .cards-container {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 87, 255, 0.25);

    margin: 40px auto;
    max-width: 1460px;
    width: calc(100% - 40px);

    .card {
      background-color: #f2eded;
      border-radius: 20px;
      border: 2px solid #f8f8f8;
      transition: transform 0.3s ease;
      align-self: stretch;

      width: 28%;
      padding: 20px;
      display: flex;
      align-items: center;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: translateY(-5px);
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .icon {
          width: 75px;
          height: 75px;
          border: 2px solid #00ca00;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 60%;
            height: 60%;
          }
        }

        .text {
          margin-left: 20px;

          h3 {
            margin: 0;
            color: #00ca00;
            font-size: 35px;
            font-weight: bold;
            font-family: "Poppins", sans-serif;
          }

          p {
            margin: 0;
            margin-top: 5px;
            color: #707070;
            font-size: 20px;
            font-family: "Poppins", sans-serif;
          }
        }
      }
    }
  }

  .electric-rides-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headline {
    font-size: 55px;
    font-weight: bold;
    color: #00ca00;
    margin: 0;
  }

  .subheadline {
    font-size: 40px;
    font-weight: bold;
    color: #00ca00;
    margin: 0;
  }

  .tagline {
    font-size: 30px;
    color: #0066ff;
    margin-top: 40px;
    font-weight: bold;
    letter-spacing: 0.1em;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .features-container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 20px;

      .feature {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border: 2px solid #00ff00;
        border-radius: 25px;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.3);

        .icon-container {
          margin-right: 10px;

          img {
            width: 30px;
            height: 30px;
          }
        }

        p {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: #00c000;
        }
      }
    }

    .cards-container {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-radius: 10px;
        background-color: #f5f5f5;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 250px;
        text-align: center;

        h3 {
          margin-bottom: 20px;
          font-size: 24px;
          font-family: "Poppins", sans-serif;
          font-weight: bold;
        }

        img {
          width: 200px;
          height: auto;
          margin-bottom: 20px;
        }

        p {
          margin: 0;
          font-size: 18px;
          color: #7f7f7f;
        }
      }
    }
  }
  // Variables
  $primary-color: #00ff00; // Replace with the green shade from your design
  $border-color: #a4d4ff; // Light blue border color for ride cards
  $background-color: #f5f9ff; // Light grayish-blue background color for ride cards
  $text-color: #333; // Darker text color
  $font-poppins-bold: "Poppins";

  .benifits-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  // Oval Cards
  .oval-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;

    .oval-card {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $primary-color;
      border-radius: 50px;
      padding: 30px 90px;
      font-family: $font-poppins-bold;
      font-size: 26px;
      color: $primary-color;
      box-shadow: 0 0 10px rgba(0, 255, 0, 0.3); // Green shadow

      span {
        z-index: 1; // Ensure text is above the icon
      }

      .border-icon {
        position: absolute;
        top: -15px;
        left: 10%;
        transform: translateX(-50%);
        height: 50px;
        background-color: #fff;
        margin: -5px;
        border-radius: 50%;
      }
    }
  }

  // Ride Cards
  .ride-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .ride-card {
      background-color: $background-color;
      border: 2px solid $border-color;
      border-radius: 15px;
      padding: 40px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 300px;

      &__text {
        font-family: $font-poppins-bold;
        font-size: 30px;
        margin-bottom: 10px;
        color: $text-color;
      }

      &__image {
        margin-top: -30%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .apply-to-drive {
    text-align: center;
    padding: 2rem;
    background-color: #ffffff;

    h1 {
      font-size: 50px;
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      color: #00ca00; // Color from your UI
      margin-bottom: 2rem;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 300px;

      .info,
      .apply-to-drive-image-container {
        flex: 1;
        margin: 0 1rem;
      }

      .info {
        text-align: left;

        p {
          margin-bottom: 2rem;
          font-size: 24px;
          color: #595959;
        }

        .benefits {
          list-style: none;
          padding: 0;

          li {
            display: flex;
            align-items: center;
            margin: 1.5rem 0;

            .icon {
              width: 80px;
              height: 80px;
              background-color: #f0f4f8;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 1rem;

              img {
                height: 50px;
              }
            }

            span {
              font-size: 24px;
              color: #6b6b6b;
            }
          }
        }
      }

      .apply-to-drive-image-container {
        position: relative;
        text-align: center;

        img {
          max-width: 100%;
          border-radius: 12px;
        }

        .apply-button {
          position: absolute;
          bottom: 20px;
          transform: translateX(-50%);
          background-color: #00ca00;
          color: #ffffff;
          border: none;
          padding: 1rem 12rem;
          border-radius: 8px;
          font-size: 18px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #24952c;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .promo-title {
      font-size: 28px;
    }

    .promo-subtitle {
      font-size: 22px;
    }

    .promo-banner {
      padding: 80px 20px 200px 50px;
    }

    .promo-card {
      width: 280px;
    }

    .cards-container {
      .card {
        width: 90%;
        margin-bottom: 20px; // Space between cards
      }
    }
  }


}

@media screen and (max-width: 768px) {
  .home {
    .promo-section {
      .promo-banner {
        padding: 25px 41px 389px 90px;
      }
      .promo-content {
        padding: 0px 35px;

        .promo-card {
          width: 300px;
          .get-started-button {
            width: 250px;
            height: 50px;
            padding: 5px;

            .button-text {
              font-size: 20px;
            }
          }
        }
      }
      .promo-banner {
        .promo-subtitle {
          font-size: 20px;
        }
        .promo-title {
          font-size: 18px;
        }
      }
    }
    .cards-container {
      margin-top: 50px;
      display: block;
      padding: 20px 0; // Scoped padding only to cards-container, not entire page
  
      .slider {
        position: relative;
        width: 100%;
        overflow: hidden;
  
        .cards-wrapper {
          display: flex;
          transition: transform 0.5s ease-in-out;
          width: 95%; /* Assuming 3 cards in total */
          gap: 5px;
          margin: 7px;
          height: 90px;
        }
  
        .card {
          min-width: 100%; /* Each card takes 100% of mobile width */
          margin: 0 auto;
          .content .icon{
            height: 60px !important;
            width: 60px !important;
          } .text h3{
            font-size: 20px !important;
          }
          p{
            font-size: 15px !important;
          }
        }
      }
    
    }
    .electric-rides-container {
      display: none;
    }
    .oval-cards {
      display: none;
    }
    .ride-cards {
      flex-direction: column;
      align-items: center;
      margin: 30px;
      .ride-card {
        width: 100%;
        margin-bottom: 20px;
        width: 240px;
        padding: 35px;
        .ride-card__text {
          font-size: 24px;
        }
      }
    }
    .apply-to-drive {
      text-align: center;
      padding: 1rem;
  
      h1 {
        font-size: 30px;
        margin-bottom: 1.5rem;
      }
  
      .content {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        .info {
          text-align: center !important;
          font-size: 16px;
  
          p{
            font-size: 20px;
          }
        }
      }
  
  
  
      .benefits {
        li
        span {
          font-size: 16px !important;
          justify-content: center;
        }
  
          .icon {
            width: 40px !important;
            height: 40px !important;
            margin-right: 3rem !important;
  
            img {
              height: 30px;
            }
          
        }
      }
  
      .apply-button {
        padding: 10px 100px !important;
        margin-bottom: 0rem;
    
      }
      .apply-to-drive-image-container {
        margin-bottom: 1.5rem;
        
    
      }
  
    }
  }
}
