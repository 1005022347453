
.custom-modal{
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); // Slightly darker overlay for better contrast
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeInOverlay 0.4s ease-out;
  }
  
  .modal-content {
    background: #fff;
    border-radius: 12px;
    width: 100%;
    max-width: 500px; // Increased max-width for a more spacious look
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); // Enhanced shadow for a more prominent effect
    display: flex;
    flex-direction: column;
    animation: slideInContent 0.4s ease-out;
  
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 2px solid #ddd;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      background: #f9f9f9; // Light background for the header
  
      .header-logo {
        height: 40px; // Slightly larger logo for better visibility
      }
  
      .close-btn {
        background: none;
        border: none;
        font-size: 28px; // Larger font size for better accessibility
        cursor: pointer;
        color: #333;
        transition: color 0.3s ease, transform 0.2s ease;
        margin-left: 20px; // Adjusted spacing for better alignment
  
        &:hover {
          color: #00CA00;
          transform: scale(1.1); // Slight scale effect on hover
        }
      }
    }
  
    .modal-body {
      padding: 20px;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      text-align: center;
  
      p {
        margin: 0;
      }
    }
  
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px;
      background: #f9f9f9; // Light background for the footer
  
      .logout-btn,
      .close-btn {
        border: none;
        border-radius: 8px;
        padding: 12px 20px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
        margin-left: 15px; // Spacing between buttons
  
        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
        }
      }
  
      .logout-btn {
        background-color: #00CA00;
        color: #fff;
  
        &:hover {
          background-color: darken(#00CA00, 10%);
          transform: scale(1.05); // Slight scale effect on hover
        }
      }
  
      .close-btn {
        background-color: #f44336;
        color: #fff;
  
        &:hover {
          background-color: darken(#f44336, 10%);
          transform: scale(1.05); // Slight scale effect on hover
        }
      }
    }
  }
}

  
  @keyframes fadeInOverlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideInContent {
    from {
      transform: translateY(-30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  